import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap"; // Import Bootstrap Modal
import { url } from "../index";

const HistoryOfIllness = () => {
  const [illnesses, setIllnesses] = useState([]);
  const [newIllness, setNewIllness] = useState("");
  const [selectedIllness, setSelectedIllness] = useState(null);
  const [showModal, setShowModal] = useState(false); // State to control modal visibility

  // Fetch illnesses on component load
  useEffect(() => {
    fetchIllnesses();
  }, []);

  const fetchIllnesses = async () => {
    try {
      const response = await axios.get(`${url}/api/historyOfIllness`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("med_token")}`,
        },
      });
      setIllnesses(response.data);
    } catch (err) {
      console.error("Error fetching illnesses:", err);
    }
  };

  const handleAddIllness = async () => {
    try {
      await axios.post(
        `${url}/api/historyOfIllness/add`,
        { illness: newIllness },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("med_token")}`,
          },
        }
      );
      setNewIllness("");
      fetchIllnesses(); // Re-fetch illnesses after adding
    } catch (err) {
      if (err.response && err.response.status === 400) {
        // If duplicate record, show the modal popup
        setShowModal(true);
      } else {
        console.error("Error adding illness:", err);
      }
    }
  };

  const handleUpdateIllness = async () => {
    try {
      await axios.put(
        `${url}/api/historyOfIllness/update/${selectedIllness.id}`,
        { illness: selectedIllness.illness },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("med_token")}`,
          },
        }
      );
      setSelectedIllness(null);
      fetchIllnesses(); // Re-fetch illnesses after updating
    } catch (err) {
      console.error("Error updating illness:", err);
    }
  };

  const handleDeleteIllness = async (id) => {
    try {
      await axios.delete(`${url}/api/historyOfIllness/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("med_token")}`,
        },
      });
      fetchIllnesses(); // Re-fetch illnesses after deleting
    } catch (err) {
      console.error("Error deleting illness:", err);
    }
  };

  return (
    <div className="w-full">
      <h1 className="text-center text-4xl font-bold text-gray-700 mb-4">History of Illness</h1>

      {/* Form to Add New Illness */}
      <div className="mb-4">
        <div className="input-group">
          <input
            type="text"
            className="form-control"
            value={newIllness}
            onChange={(e) => setNewIllness(e.target.value)}
            placeholder="Enter new illness"
          />
          <button className="btn btn-primary" onClick={handleAddIllness}>
            Add Illness
          </button>
        </div>
      </div>

      {/* List of Illnesses */}
      <ul className="list-group">
        {illnesses.map((ill) => (
          <li key={ill.ID} className="list-group-item d-flex justify-content-between align-items-center">
            {ill.illness}
            <div>
              <button className="btn btn-warning btn-sm me-2" onClick={() => setSelectedIllness(ill)}>
                Edit
              </button>
              <button className="btn btn-danger btn-sm" onClick={() => handleDeleteIllness(ill.id)}>
                Delete
              </button>
            </div>
          </li>
        ))}
      </ul>

      {/* Edit Illness Form */}
      {selectedIllness && (
        <div className="mt-4">
          <h4>Edit Illness</h4>
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              value={selectedIllness.illness}
              onChange={(e) => setSelectedIllness({ ...selectedIllness, illness: e.target.value })}
            />
            <button className="btn btn-success" onClick={handleUpdateIllness}>
              Update Illness
            </button>
          </div>
        </div>
      )}

      {/* Modal Popup */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Duplicate Entry</Modal.Title>
        </Modal.Header>
        <Modal.Body>The illness you are trying to add already exists.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default HistoryOfIllness;
