import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Button, Modal, Form, Input, message } from "antd";
import { url } from "../index";

const FrequencyList = () => {
  const [frequencies, setFrequencies] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentFrequency, setCurrentFrequency] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchFrequencies();
  }, []);

  // Fetch Frequencies
  const fetchFrequencies = async () => {
    try {
      const response = await axios.get(`${url}/api/frequencies`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("med_token")}`,
        },
      });
      setFrequencies(response.data.data);
    } catch (error) {
      console.error("Error fetching frequencies:", error);
      message.error("Failed to fetch frequencies");
    }
  };

  // Open the modal for adding a new frequency
  const handleAdd = () => {
    form.resetFields();
    setCurrentFrequency(null);
    setIsModalVisible(true);
  };

  // Open the modal for editing a frequency
  const handleEdit = (frequency) => {
    setCurrentFrequency(frequency);
    form.setFieldsValue({ FrequencyName: frequency.Name });
    setIsModalVisible(true);
  };

  // Delete a frequency
  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this frequency?")) {
      try {
        await axios.delete(`${url}/api/frequencies/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("med_token")}`,
          },
        });
        fetchFrequencies();
        message.success("Frequency deleted successfully");
      } catch (error) {
        console.error("Error deleting frequency:", error);
        message.error("Failed to delete frequency");
      }
    }
  };

  // Add or Update Frequency
  const handleSubmit = async (values) => {
    try {
      let response;
      if (currentFrequency) {
        // Update existing frequency
        await axios.put(
          `${url}/api/frequencies/${currentFrequency.ID}`,
          { Name: values.FrequencyName },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("med_token")}`,
            },
          }
        );
        message.success("Frequency updated successfully");
      } else {
        // Add new frequency
        await axios.post(
          `${url}/api/frequencies`,
          { Name: values.FrequencyName },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("med_token")}`,
            },
          }
        );
        message.success("Frequency added successfully");
      }
      fetchFrequencies(); // Refresh the list after add/update
      setIsModalVisible(false);
      form.resetFields();
    } catch (error) {
      console.error("Error saving frequency:", error);
      message.error("Failed to save frequency");
    }
  };

  // Table Columns
  const columns = [
    {
      title: "Frequency",
      dataIndex: "Name",
      key: "name",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <>
          <Button type="primary" onClick={() => handleEdit(record)}>
            Edit
          </Button>
          <Button type="danger" onClick={() => handleDelete(record.ID)} style={{ marginLeft: 8 }}>
            Delete
          </Button>
        </>
      ),
    },
  ];

  return (
    <div className="container mt-4">
      <h2>Manage Frequencies</h2>
      <Button type="primary" onClick={handleAdd} style={{ marginBottom: 16 }}>
        Add New Frequency
      </Button>
      <Table
        dataSource={frequencies}
        columns={columns}
        rowKey="ID"
        // pagination={{ pageSize: 5 }}
      />

      <Modal
        title={currentFrequency ? "Edit Frequency" : "Add New Frequency"}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <Form form={form} onFinish={handleSubmit}>
          <Form.Item label="Frequency Name" name="FrequencyName" rules={[{ required: true, message: "Please input the frequency name!" }]}>
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {currentFrequency ? "Update" : "Add"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default FrequencyList;
