import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Button, Modal, Form, Input, message } from "antd";
import { url } from "../index";

const MedicineTimeList = () => {
  const [medicineTimes, setMedicineTimes] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentMedicineTime, setCurrentMedicineTime] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchMedicineTimes();
  }, []);

  const fetchMedicineTimes = async () => {
    try {
      const response = await axios.get(`${url}/api/medicine-times`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("med_token")}`,
        },
      });
      setMedicineTimes(response.data.data);
    } catch (error) {
      console.error("Error fetching medicine times:", error);
      message.error("Failed to fetch medicine times");
    }
  };

  const handleAdd = () => {
    form.resetFields();
    setCurrentMedicineTime(null);
    setIsModalVisible(true);
  };

  const handleEdit = (medicineTime) => {
    console.log(medicineTime, "edit selected")
    ;
    setCurrentMedicineTime(medicineTime);
    form.setFieldsValue({ Name: medicineTime.Name, NameHindi: medicineTime.NameHindi });
    setIsModalVisible(true);
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this medicine time?")) {
      try {
        await axios.delete(`${url}/api/medicine-times/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("med_token")}`,
          },
        });
        setMedicineTimes((prev) => prev.filter((item) => item.ID !== id)); // Remove from state immediately
        message.success("Medicine time deleted successfully");
      } catch (error) {
        console.error("Error deleting medicine time:", error);
        message.error("Failed to delete medicine time");
      }
    }
  };

//   console.log(currentMedicineTime, "currentMedicineTime 👻👻👻👻")

  const handleSubmit = async (values) => {
    try {
      let response;
      if (currentMedicineTime) {
        response = await axios.put(
          `${url}/api/medicine-times/${currentMedicineTime.ID}`,
          {
            Name: values.Name,
            NameHindi: values.NameHindi,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("med_token")}`,
            },
          }
        );
        setMedicineTimes((prev) =>
          prev.map((item) => (item.ID === currentMedicineTime.ID ? { ...item, Name: values.Name, NameHindi: values.NameHindi } : item))
        );
        message.success("Medicine time updated successfully");
      } else {
        response = await axios.post(
          `${url}/api/medicine-times`,
          {
            Name: values.Name,
            NameHindi: values.NameHindi,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("med_token")}`,
            },
          }
        );
        console.log(response.data.data[0], "submitted data 😜😜😜😜")
        setMedicineTimes((prev) => [{ ...response.data.data[0] }, ...prev]);
        message.success("Medicine time added successfully");
      }

      setIsModalVisible(false);
      form.resetFields();
      setCurrentMedicineTime(null);
    } catch (error) {
      console.error("Error saving medicine time:", error);
      message.error("Failed to save medicine time");
    }
  };

  const columns = [
    {
      title: "Medicine Time (English)",
      dataIndex: "Name",
      key: "Name",
    },
    {
      title: "Medicine Time (Hindi)",
      dataIndex: "NameHindi",
      key: "NameHindi",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <>
          <Button type="primary" onClick={() => handleEdit(record)} style={{ marginRight: 8 }}>
            Edit
          </Button>
          <Button type="danger" onClick={() => handleDelete(record.ID)}>
            Delete
          </Button>
        </>
      ),
    },
  ];

  return (
    <div className="container mt-4">
      <h2>Manage Medicine Times</h2>
      <Button type="primary" onClick={handleAdd} style={{ marginBottom: 16 }}>
        Add New Medicine Time
      </Button>
      <Table dataSource={medicineTimes} columns={columns} rowKey="ID" />

      <Modal
        title={currentMedicineTime ? "Edit Medicine Time" : "Add New Medicine Time"}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <Form form={form} onFinish={handleSubmit}>
          <Form.Item label="Medicine Time (English)" name="Name" rules={[{ required: true, message: "Please input the medicine time!" }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Medicine Time (Hindi)" name="NameHindi" rules={[{ required: true, message: "Please input the medicine time in Hindi!" }]}>
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {currentMedicineTime ? "Update" : "Add"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default MedicineTimeList;
