import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Button, Modal, Form, Input, Select, message } from "antd";
import { url } from "../index";

const { Option } = Select;

const DosageList = () => {
  const [dosages, setDosages] = useState([]); // Initialize dosages as an empty array
  const [medicineTimes, setMedicineTimes] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentDosage, setCurrentDosage] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchDosages();
    fetchMedicineTimes();
  }, []);

  const fetchDosages = async () => {
    try {
      const response = await axios.get(`${url}/api/dosages`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("med_token")}`,
        },
      });
      setDosages(Array.isArray(response.data.data) ? response.data.data : []);
    } catch (error) {
      message.error("Failed to fetch dosages");
    }
  };

  const fetchMedicineTimes = async () => {
    try {
      const response = await axios.get(`${url}/api/medicine-times`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("med_token")}`,
        },
      });
      setMedicineTimes(Array.isArray(response.data.data) ? response.data.data : []);
    } catch (error) {
      message.error("Failed to fetch medicine times");
    }
  };

  const handleAdd = () => {
    form.resetFields();
    setCurrentDosage(null);
    setIsModalVisible(true);
  };

  const handleEdit = (dosage) => {
    const matchedMedicineTime = medicineTimes.find((time) => time.Name === dosage.MedicineTimeName);
    const medicineTimeID = matchedMedicineTime ? matchedMedicineTime.ID : null;

    setCurrentDosage(dosage);
    form.setFieldsValue({
      DosagePattern: dosage.DosagePattern,
      MedicineTimeID: medicineTimeID,
    });
    setIsModalVisible(true);
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this dosage?")) {
      try {
        await axios.delete(`${url}/api/dosages/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("med_token")}`,
          },
        });
        setDosages((prev) => prev.filter((item) => item.ID !== id)); // Remove from state immediately

        message.success("Dosage deleted successfully");
      } catch (error) {
        console.error("Error deleting dosage:", error);
        message.error("Failed to delete dosage");
      }
    }
  };

  const handleSubmit = async (values) => {
    const trimmedDosagePattern = values.DosagePattern.trim();
    try {
      if (currentDosage) {
        await axios.put(
          `${url}/api/dosages/${currentDosage.ID}`,
          {
            DosagePattern: trimmedDosagePattern,
            MedicineTimeID: values.MedicineTimeID,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("med_token")}`,
            },
          }
        );
        message.success("Dosage updated successfully");
      } else {
        await axios.post(
          `${url}/api/dosages`,
          {
            DosagePattern: trimmedDosagePattern,
            MedicineTimeID: values.MedicineTimeID,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("med_token")}`,
            },
          }
        );
        message.success("Dosage added successfully");
      }
      fetchDosages();
      setIsModalVisible(false);
    } catch (error) {
      message.error("Failed to save dosage");
    }
  };

  const columns = [
    {
      title: "Dosage Pattern",
      dataIndex: "DosagePattern",
      key: "DosagePattern",
    },
    {
      title: "Medicine Time",
      dataIndex: "MedicineTimeName",
      key: "MedicineTimeName",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <>
          <Button type="primary" onClick={() => handleEdit(record)}>
            Edit
          </Button>
          <Button type="danger" onClick={() => handleDelete(record.ID)} style={{ marginLeft: 8 }}>
            Delete
          </Button>
        </>
      ),
    },
  ];

  return (
    <div className="container mt-4">
      <h2>Manage Dosages</h2>
      <Button type="primary" onClick={handleAdd} style={{ marginBottom: 16 }}>
        Add New Dosage
      </Button>
      <Table
        dataSource={dosages || []}
        columns={columns}
        rowKey="ID" // Ensure each row has a unique key based on ID
      />
      <Modal
        title={currentDosage ? "Edit Dosage" : "Add New Dosage"}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <Form form={form} onFinish={handleSubmit}>
          <Form.Item label="Dosage Pattern" name="DosagePattern" rules={[{ required: true, message: "Please input the dosage pattern!" }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Medicine Time" name="MedicineTimeID" rules={[{ required: true, message: "Please select the medicine time!" }]}>
            <Select placeholder="Select a medicine time">
              {medicineTimes.map((time) => (
                <Option key={time.ID} value={time.ID}>
                  {time.Name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {currentDosage ? "Update" : "Add"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default DosageList;
