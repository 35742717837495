// frontend\src\components\VaccineList.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Button, Table, Space } from 'antd';
import './VaccineList.css'; // Import custom CSS file
import { url } from '../index';

// const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const VaccineList = () => {
    const [vaccines, setVaccines] = useState([]);
    const navigate = useNavigate();

    console.log("This is the vaccine list", vaccines)
    console.log("state called 😂😂")

    useEffect(() => {
        const fetchVaccines = async () => {
            try {
                // setVaccines([]);
                const response = await axios.get(`${url}/api/vaccines`);
                setVaccines(response.data);
            } catch (error) {
                console.error('Error fetching vaccines', error);
            }
        };
        fetchVaccines();
    }, []);

    const handleEdit = (id) => {
        navigate(`/edit-vaccine/${id}`);
    };

    const handleDelete = async (id) => {
        if (window.confirm('Are you sure you want to delete this vaccine?')) {
            try {
                await axios.delete(`${url}/api/vaccines/${id}`);
                setVaccines(vaccines.filter(vaccine => vaccine.ID !== id));
            } catch (error) {
                console.error('Error deleting vaccine', error);
            }
        }
    };

    const handleAddNew = () => {
        navigate('/add-vaccine');
    };

    const columns = [
        {
            title: 'Vaccine Name',
            dataIndex: 'VaccineName',
            key: 'VaccineName',
        },
        {
            title: 'Vaccine Brands',
            dataIndex: 'VaccineBrandName',
            key: 'VaccineBrandName',
        },
        {
            title: 'Days After Birth',
            dataIndex: 'DaysAfterBirth',
            key: 'DaysAfterBirth',
        },
        {
            title: 'Age Group',
            dataIndex: 'AgeGroupName',
            key: 'AgeGroupName',
        },
        {
            title: 'Dependent On',
            dataIndex: 'DependentVaccineName',
            key: 'DependentVaccineName',
        },
        {
            title: 'Dependent Days',
            dataIndex: 'DependentDays',
            key: 'DependentDays',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <Space size="middle">
                    <Button 
                        type="primary" 
                        className="edit-button"
                        onClick={() => handleEdit(record.ID)}
                    >
                        Edit
                    </Button>
                    <Button 
                        type="danger" 
                        className="delete-button"
                        onClick={() => handleDelete(record.ID)}
                    >
                        Delete
                    </Button>
                </Space>
            ),
        },
    ];

    return (
        <div className="container mt-4">
            <div className="header">
                <h2 className="mb-4">Vaccine List</h2>
                <Button 
                    type="primary" 
                    className="add-new-button" 
                    onClick={handleAddNew}
                >
                    Add New Vaccine
                </Button>
            </div>
            <Table 
                dataSource={vaccines} 
                columns={columns} 
                rowKey="ID" 
                pagination={false} 
                bordered
                className="custom-table" // Custom class for the table
            />
        </div>
    );
};

export default VaccineList;
