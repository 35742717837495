import React from 'react';
import { Link } from 'react-router-dom';

const Dashboard = () => {
    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <Link className="navbar-brand" to="/">Child Vaccination</Link>
            <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <Link className="nav-link" to="/vaccine-brands">Vaccine Brands</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/age-groups">Age Groups</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/vaccines">Vaccines</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/logout">Logout</Link>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default Dashboard;
