import React from 'react';
import App from './App';
import { createRoot } from 'react-dom/client';

// Exporting the API base URL
export const url = ''; // Change this as needed


createRoot(document.getElementById('root')).render(
  <React.StrictMode>
        <App />
  </React.StrictMode>
);