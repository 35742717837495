import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // useNavigate instead of useHistory
import { url } from '../index';

const LoginForm = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate(); // initialize useNavigate

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${url}/api/users/login`, { username, password });
            console.log(response.data);
            if (response.data.success) {
                localStorage.setItem('med_token', response.data.token);
                navigate('/dashboard'); // navigate to the dashboard
            } else {
                alert(response.data.message || 'Invalid credentials');
            }
        } catch (error) {
            if (error.response && error.response.data) {
                console.error('Login error:', error.response.data);
                alert(`Error: ${error.response.data.message}`);
            } else {
                console.error('Login error:', error);
                alert('An unexpected error occurred. Please try again later.');
            }
        }
    };

    return (
        <div className="max-w-2xl mx-auto">
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Username</label>
                    <input type="text" className="form-control" value={username} onChange={(e) => setUsername(e.target.value)} required />
                </div>
                <div className="form-group">
                    <label>Password</label>
                    <input type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} required />
                </div>
                <button type="submit" className="btn btn-primary">Login</button>
            </form>
        </div>
    );
};

export default LoginForm;
