import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, Modal, Form, Input, notification, message } from 'antd';
import { url } from '../index';

const InvestigationAdviced = () => {
    const [investigations, setInvestigations] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentInvestigation, setCurrentInvestigation] = useState(null);
    const [form] = Form.useForm();

    useEffect(() => {
        fetchInvestigations();
    }, []);

    notification.config({
        duration: 2,
      });

    // Fetch Investigations
    const fetchInvestigations = async () => {
        try {
            const response = await axios.get(`${url}/api/investigation-adviced`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('med_token')}`,
                },
            });
            setInvestigations(response.data);
        } catch (error) {
            console.error('Error fetching investigations:', error);
            message.error('Failed to fetch investigations');
        }
    };

    // Open modal for adding a new investigation
    const handleAdd = () => {
        form.resetFields();
        setCurrentInvestigation(null);
        setIsModalVisible(true);
    };

    // Open modal for editing an investigation
    const handleEdit = (investigation) => {
        setCurrentInvestigation(investigation);
        form.setFieldsValue({ name: investigation.name });
        setIsModalVisible(true);
    };

    // Delete an investigation
    const handleDelete = async (id) => {
        if (window.confirm('Are you sure you want to delete this investigation?')) {
            try {
                await axios.delete(`${url}/api/investigation-adviced/${id}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('med_token')}`,
                    },
                });
                notification.success({ message: 'Investigation deleted successfully' });
                fetchInvestigations();
            } catch (error) {
                console.error('Error deleting investigation:', error);
                notification.error({ message: 'Error deleting investigation' });
            }
        }
    };

    // Add or Update Investigation
    const handleSubmit = async (values) => {
        try {
            if (currentInvestigation) {
                // Update existing investigation
                await axios.put(`${url}/api/investigation-adviced/${currentInvestigation.id}`, values, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('med_token')}`,
                    },
                });
                notification.success({ message: 'Investigation updated successfully' });
            } else {
                // Add new investigation
                await axios.post(`${url}/api/investigation-adviced`, values, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('med_token')}`,
                    },
                });
                notification.success({ message: 'Investigation added successfully' });
            }
            fetchInvestigations();
            setIsModalVisible(false);
            form.resetFields();
        } catch (error) {
            console.error('Error saving investigation:', error);
            notification.error({ message: 'Error saving investigation' });
        }
    };

    // Table Columns
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <>
                    <Button type="primary" onClick={() => handleEdit(record)}>Edit</Button>
                    <Button type="danger" onClick={() => handleDelete(record.id)} style={{ marginLeft: 8 }}>Delete</Button>
                </>
            ),
        },
    ];

    return (
        <div className="container mt-4">
            <div className='flex justify-between items-center mb-3'>
                <h2 className='text-xl text-gray-700 font-semibold'>Manage Investigations Advised</h2>
                <Button type="primary" onClick={handleAdd} style={{ marginBottom: 16 }}>
                    Add New Investigation
                </Button>
            </div>
            <Table
                dataSource={investigations}
                columns={columns}
                rowKey="id"
                // pagination={{ pageSize: 5 }}
            />

            <Modal
                title={currentInvestigation ? "Edit Investigation" : "Add New Investigation"}
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                footer={null}
            >
                <Form form={form} onFinish={handleSubmit}>
                    <Form.Item
                        label="Investigation Name"
                        name="name"
                        rules={[{ required: true, message: 'Please input the investigation name!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            {currentInvestigation ? "Update" : "Add"}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default InvestigationAdviced;
