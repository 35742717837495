import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, Modal, Form, Input, notification, message } from 'antd';
import { url } from '../index';

const Diagnoses = () => {
    const [diagnoses, setDiagnoses] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentDiagnosis, setCurrentDiagnosis] = useState(null);
    const [form] = Form.useForm();

    useEffect(() => {
        fetchDiagnoses();
    }, []);

    // Fetch Diagnoses
    const fetchDiagnoses = async () => {
        try {
            const response = await axios.get(`${url}/api/diagnoses`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('med_token')}`,
                },
            });
            setDiagnoses(response.data.data);
        } catch (error) {
            console.error('Error fetching diagnoses:', error);
            message.error('Failed to fetch diagnoses');
        }
    };

    // Open modal for adding a new diagnosis
    const handleAdd = () => {
        form.resetFields();
        setCurrentDiagnosis(null);
        setIsModalVisible(true);
    };

    // Open modal for editing a diagnosis
    const handleEdit = (diagnosis) => {
        setCurrentDiagnosis(diagnosis);
        form.setFieldsValue({ name: diagnosis.name });
        setIsModalVisible(true);
    };

    notification.config({
        duration: 2,
      });

    // Delete a diagnosis
    const handleDelete = async (id) => {
        if (window.confirm('Are you sure you want to delete this diagnosis?')) {
            try {
                await axios.delete(`${url}/api/diagnoses/${id}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('med_token')}`,
                    },
                });
                notification.success({ message: 'Diagnosis deleted successfully' });
                fetchDiagnoses();
            } catch (error) {
                console.error('Error deleting diagnosis:', error);
                notification.error({ message: 'Error deleting diagnosis' });
            }
        }
    };

    // Add or Update Diagnosis
    const handleSubmit = async (values) => {
        try {
            if (currentDiagnosis) {
                // Update existing diagnosis
                await axios.put(`${url}/api/diagnoses/${currentDiagnosis.id}`, values, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('med_token')}`,
                    },
                });
                notification.success({ message: 'Diagnosis updated successfully' });
            } else {
                // Add new diagnosis
                await axios.post(`${url}/api/diagnoses`, values, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('med_token')}`,
                    },
                });
                notification.success({ message: 'Diagnosis added successfully' });
            }
            fetchDiagnoses();
            setIsModalVisible(false);
            form.resetFields();
        } catch (error) {
            console.error('Error saving diagnosis:', error);
            notification.error({ message: 'Error saving diagnosis' });
        }
    };

    // Table Columns
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <>
                    <Button type="primary" onClick={() => handleEdit(record)}>Edit</Button>
                    <Button type="danger" onClick={() => handleDelete(record.id)} style={{ marginLeft: 8 }}>Delete</Button>
                </>
            ),
        },
    ];

    return (
        <div className="container mt-4">
            <div className='flex justify-between items-center mb-3'>
                <h2 className='text-xl text-gray-700 font-semibold'>Manage Diagnoses</h2>
                <Button type="primary" onClick={handleAdd} style={{ marginBottom: 16 }}>
                    Add New Diagnosis
                </Button>
            </div>
            <Table
                dataSource={diagnoses}
                columns={columns}
                rowKey="id"
                // pagination={{ pageSize: 5 }}
            />

            <Modal
                title={currentDiagnosis ? "Edit Diagnosis" : "Add New Diagnosis"}
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                footer={null}
            >
                <Form form={form} onFinish={handleSubmit}>
                    <Form.Item
                        label="Diagnosis Name"
                        name="name"
                        rules={[{ required: true, message: 'Please input the diagnosis name!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            {currentDiagnosis ? "Update" : "Add"}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default Diagnoses;
