import React, { useState } from "react";
import { Link } from "react-router-dom";
import sidebarData from "../../data/sidebarData";

const Navbar = () => {
  const [dropdownOpen, setDropdownOpen] = useState(null);

  const toggleDropdown = (index) => {
    setDropdownOpen(dropdownOpen === index ? null : index);
  };

  return (
    <nav className="bg-gray-50 w-[250px] h-screen overflow-y-auto shadow-md">
      <div className="bg-gray-50 p-4 flex items-start justify-start flex-col">
        <Link className="text-xl font-bold mb-6 text-gray-700" to="/dashboard">
          Child Vaccination
        </Link>

          <ul className="flex flex-col space-y-3">
            {sidebarData.map((item, index) => (
              <li key={index} className="relative group">
                {item.subItems ? (
                  <>
                    <div
                      onClick={() => toggleDropdown(index)}
                      className="text-gray-700 cursor-pointer text-start hover:text-blue-700"
                    >
                      {item.label}
                    </div>
                    <div
                      className={`absolute left-0 mt-2 w-48 bg-white rounded-lg shadow-lg z-50 ${
                        dropdownOpen === index ? "block" : "hidden"
                      }`}
                    >
                      {item.subItems.map((subItem, subIndex) => (
                        <Link
                          key={subIndex}
                          to={subItem.link}
                          className="block px-2 text-sm py-2 text-gray-700 hover:bg-gray-200"
                        >
                          {subItem.label}
                        </Link>
                      ))}
                    </div>
                  </>
                ) : (
                  <Link
                    to={item.link}
                    className="text-gray-700 hover:text-gray-900"
                  >
                    {item.label}
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </div>
    </nav>
  );
};

export default Navbar;