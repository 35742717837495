export default [
  {
    label: "Dashboard",
    link: "/dashboard",
  },
  {
    label: "Manage Vaccination",
    subItems: [
      { label: "Manage Vaccines", link: "/vaccines" },
      { label: "Manage Vaccine Brands", link: "/vaccine-brands" },
      { label: "Manage Age Group", link: "/age-groups" },
    ],
  },
  {
    label: "Manage Medicine",
    subItems: [
      { label: "Manage Type", link: "/manage-medicine/type" },
      { label: "Manage Units", link: "/manage-units" },
      { label: "Manage Frequency", link: "/manage-frequency" },
      { label: "Manage Medicine Time", link: "/medicine-times" },
      { label: "Manage Route", link: "/medicine-route" },
      { label: "Manage Dosage", link: "/manage-dosage" },
      { label: "Manage Medicine", link: "/add-medicine" },
    ],
  },
  {
    label: "Manage Symptoms",
    subItems: [{ label: "Manage Symptoms", link: "/symptoms-list" }],
  },
  {
    label: "Manage History of Illness",
    subItems: [{ label: "Manage History of Illness", link: "/history-of-illness" }],
  },
  {
    label: "Manage History of Surgery",
    link: "/history-of-surgery",
  },
  {
    label: "Clinical Findings",
    link: "/clinical-findings",
  },
  {
    label: "Diagnoses",
    link: "/diagnoses",
  },
  {
    label: "Investigation Adviced",
    link: "/investigation-adviced",
  },
  {
    label: "Logout",
    link: "/logout",
  },
];
