import React from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom'; // Use BrowserRouter
import Navbar from './components/Navbar';
import LoginForm from './components/LoginForm';
import VaccineList from './components/VaccineList';
import VaccineForm from './components/VaccineForm';
import VaccineBrandList from './components/VaccineBrandList';
import VaccineBrandForm from './components/VaccineBrandForm';
import AgeGroupList from './components/AgeGroupList';
import AgeGroupForm from './components/AgeGroupForm';
import Logout from './components/Logout';
import MedicineTypeList from './components/MedicineTypeList';
import UnitList from './components/UnitList';
import DosageList from './components/DosageList';
import FrequencyList from './components/FrequencyList';
import MedicineTimeList from './components/MedicineTimeList';
import MedicineForm from './components/MedicineForm';
import MedicineSearch from './components/MedicineSearch'; // This is the component we want to use
import Dashboard from './components/Dashboard';
import MedicineRoute from './components/MedicineRoute';
import SymptomRoute from './components/SymptomsList';
import HistoryOfIllnessListRoute from './components/HistoryOfIllnessList';
import HistoryOfSurgery from './components/HistoryOfSurgery';
import ClinicalFindings from './components/ClinicalFindings';
import Diagnoses from './components/Diagnoses';
import InvestigationAdviced from './components/InvestigationAdviced';


const AppContent = () => {
    const location = useLocation();
    
    return (
        <div className="App flex justify-start items-start">
            {location.pathname !== '/' && <Navbar />}
            <div className="h-screen w-full p-4 overflow-y-auto">
                <Routes>
                    <Route path="/" element={<LoginForm />} />
                    <Route path="/dasboard" element={<Dashboard />} />
                    <Route path="/vaccines" element={<VaccineList />} />
                    <Route path="/add-vaccine" element={<VaccineForm />} />
                    <Route path="/vaccine-brands" element={<VaccineBrandList />} />
                    <Route path="/add-vaccine-brand" element={<VaccineBrandForm />} />
                    <Route path="/age-groups" element={<AgeGroupList />} />
                    <Route path="/add-age-group" element={<AgeGroupForm />} />
                    <Route path="/logout" element={<Logout />} />
                    <Route path="/edit-age-group/:id" element={<AgeGroupForm />} />
                    <Route path="/edit-vaccine-brand/:id" element={<VaccineBrandForm />} />
                    <Route path="/edit-vaccine/:id" element={<VaccineForm />} />
                    <Route path="/manage-medicine/type" element={<MedicineTypeList />} />
                    <Route path="/manage-units" element={<UnitList />} />
                    <Route path="/manage-dosage" element={<DosageList />} />
                    <Route path="/manage-frequency" element={<FrequencyList />} />
                    <Route path="/medicine-times" element={<MedicineTimeList />} />
                    <Route path="/add-medicine" element={<MedicineForm />} />
                    <Route path="/search-medicine" element={<MedicineSearch />} /> 
                    <Route path="/medicine-route" element={<MedicineRoute />} />
                    <Route path="/symptoms-list" element={<SymptomRoute />} />
                    <Route path="/history-of-illness" element={<HistoryOfIllnessListRoute />} />
                    <Route path="/history-of-surgery" element={<HistoryOfSurgery />} />
                    <Route path="/clinical-findings" element={<ClinicalFindings />} />
                    <Route path="/diagnoses" element={<Diagnoses />} />
                    <Route path="/investigation-adviced" element={<InvestigationAdviced />} />
                </Routes>
            </div>
        </div>
    );
};

const App = () => (
    <BrowserRouter>
        <AppContent />
    </BrowserRouter>
);

export default App;
