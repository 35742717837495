import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, Modal, Form, Input, notification, message } from 'antd';
import { url } from '../index';

const UnitList = () => {
    const [units, setUnits] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentUnit, setCurrentUnit] = useState(null);
    const [form] = Form.useForm();

    useEffect(() => {
        fetchUnits();
    }, []);

    notification.config({
        duration: 2,
      });

    // Fetch Units
    const fetchUnits = async () => {
        try {
            const response = await axios.get(`${url}/api/units`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('med_token')}`,
                },
            });
            setUnits(response.data.data);
        } catch (error) {
            console.error('Error fetching units:', error);
            message.error('Failed to fetch units');
        }
    };

    // Open the modal for adding a new unit
    const handleAdd = () => {
        form.resetFields();
        setCurrentUnit(null);
        setIsModalVisible(true);
    };

    // Open the modal for editing a unit
    const handleEdit = (unit) => {
        setCurrentUnit(unit);
        form.setFieldsValue({ Name: unit.Name });
        setIsModalVisible(true);
    };

    // Delete a unit
    const handleDelete = async (id) => {
        if (window.confirm('Are you sure you want to delete this unit?')) {
            try {
                await axios.delete(`${url}/api/units/${id}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('med_token')}`,
                    },
                });
                notification.success({ message: 'Unit deleted successfully' });
                fetchUnits();
            } catch (error) {
                console.error('Error deleting unit:', error);
                notification.error({ message: 'Error deleting unit' });
            }
        }
    };

    // Add or Update Unit
    const handleSubmit = async (values) => {
        try {
            let response;
            if (currentUnit) {
                // Update existing unit
                await axios.put(`${url}/api/units/${currentUnit.Id}`, values, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('med_token')}`,
                    },
                });
                notification.success({ message: 'Unit updated successfully' });
            } else {
                // Add new unit
                await axios.post(`${url}/api/units`, values, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('med_token')}`,
                    },
                });
                notification.success({ message: 'Unit added successfully' });
            }
            fetchUnits(); // Refresh the list after add/update
            setIsModalVisible(false);
            form.resetFields();
        } catch (error) {
            console.error('Error saving unit:', error);
            notification.error({ message: 'Error saving unit' });
        }
    };

    // Table Columns
    const columns = [
        {
            title: 'Name',
            dataIndex: 'Name',
            key: 'name',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <>
                    <Button type="primary" onClick={() => handleEdit(record)}>Edit</Button>
                    <Button type="danger" onClick={() => handleDelete(record.Id)} style={{ marginLeft: 8 }}>Delete</Button>
                </>
            ),
        },
    ];

    return (
        <div className="container mt-4">
            <h2>Manage Units</h2>
            <Button type="primary" onClick={handleAdd} style={{ marginBottom: 16 }}>
                Add New Unit
            </Button>
            <Table
                dataSource={units}
                columns={columns}
                rowKey="Id"
                // pagination={{ pageSize: 5 }}
            />

            <Modal
                title={currentUnit ? "Edit Unit" : "Add New Unit"}
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                footer={null}
            >
                <Form form={form} onFinish={handleSubmit}>
                    <Form.Item
                        label="Unit Name"
                        name="Name"
                        rules={[{ required: true, message: 'Please input the unit name!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            {currentUnit ? "Update" : "Add"}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default UnitList;
